<template>
  <main class="maincontent">
    <div class="yourplan">
      <div class="wrapper">
        <a @click.prevent="redirect" href="">Show Exercise Log</a>
      </div>
    </div>
    <div class="mainh1">
      <div class="wrapper">
        <div class="mainh1_flex">
          <h1>Your Workout Log</h1>
        </div>
      </div>
    </div>
    <section class="log_section">
      <div class="wrapper">
        <div class="log2_title" v-if="logs.length">
          You have marked these workouts as Completed. <br />Good job!
        </div>
        <div class="log2_title" v-else>
          You have not marked any workouts as Completed.
        </div>
        <div class="log2_list">
          <div class="log2_item" v-for="log in logs" :key="log.id">
            <div class="log2_date">
              {{ log.creationDate | moment("MMMM Do, YYYY") }}:
            </div>
            <div class="log2_workout">
              {{ log.gender }}, {{ log.bodyType }}, {{ log.frequency }} Times A
              Week
            </div>
            <div class="log2_day">Month {{ log.month }}, Day {{ log.day }}</div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>


<script>
import $ from "jquery";
import { getWorkoutLogList } from "@/api/workoutlog";
export default {
  name: "Workoutlog",
  data() {
    return {
      logs: [],
    };
  },
  methods: {
    redirect() {
      this.$router.push("/log/index");
    },
  },
  created() {
    getWorkoutLogList()
      .then(({ data }) => {
        this.logs = data;
      })
      .catch((error) => {
        console.log("error while fetching workout log list", error);
      });
  },
  mounted() {
    $(".atuicons-clock").closest("li").addClass("active");
  },
  beforeDestroy() {
    $(".atuicons-clock").closest("li").removeClass("active");
  },
};
</script>

<style scoped>
@import "~@/assets/css/logpage.css";
</style>
